import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class SubmissionRequest {
    getAll() {
        return (
            axios({
                method: 'get',
                url: PATH__.api + "submissions/index.php",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    add(data) {
        return (
            axios({
                method: 'post',
                url: PATH__.api + "submissions/index.php",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }
}

export default SubmissionRequest;