import React from 'react';
import { Route } from 'react-router';
import { BrowserRouter, Routes } from 'react-router-dom';


/**Importation de la vue principal*/
import Home from "../screens/home.screen";
import Dashboard from '../screens/dashboard.screen';
import DashboardSubmissionList from '../screens/submissionList.screen';

class ROUTES extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}></Route>
                    <Route path="/dashboard" element={<Dashboard/>}>
                        <Route path="/dashboard/Qty632yuiytwqgdw73728gd268dyd28ys3287y" element={<DashboardSubmissionList/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        )
    }
}

export default ROUTES;