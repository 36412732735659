import React from 'react';
import { connect } from 'react-redux';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */


/**----------------------------------------------------------------------------------------*/
/** Importation des screens */



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */



/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class DashboardSubmissionList extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            submissionList: [],
            column1_attr: "poste_occuper",
            column2_attr: "service_travaillez_vous",
            column3_attr: "service_departement_direction_hierarchi",
            column4_attr: "superieur_hierarchique",
            column5_attr: "a_qui_reportez_vous",
            

            optionsList: [
                {
                  "text": "Quel poste occupez-vous ?",
                  "value": "poste_occuper"
                },
                {
                  "text": "Dans quel service travaillez-vous ?",
                  "value": "service_travaillez_vous"
                },
                {
                  "text": "A quels service(s) ou département(s) ou direction(s) êtes-vous hiérarchiquement rattaché ?",
                  "value": "service_departement_direction_hierarchi"
                },
                {
                  "text": "Qui est votre supérieur hiérarchique direct ?",
                  "value": "superieur_hierarchique"
                },
                {
                  "text": "A qui reportez-vous dans le service ?",
                  "value": "a_qui_reportez_vous"
                },
                {
                  "text": "Qui, au sein du service/département/direction affecte les missions ?",
                  "value": "qui_au_sein_affecte_les_missions"
                },
                {
                  "text": "Quelles sont les missions/activités régaliennes que vous devez accomplir dans le cadre de votre poste ?",
                  "value": "missions"
                },
                {
                  "text": "Quelles sont les autres missions que vous accomplissez de façon fréquente en dehors de vos missions régaliennes ?",
                  "value": "othersMission"
                },
                {
                  "text": "Avez-vous une définition écrite de chacune de vos missions ?",
                  "value": "avez_vous_une_definition_ecrite_de_chacune_de_vos_missions"
                },
                {
                  "text": "Quels sont les divers points que comprend les définitions de vos missions ?",
                  "value": "divers_points_que_comprend_les_definitions_de_vos_missions"
                },
                {
                  "text": "Existe-t-il des instances spécifiques pour traiter et arbitrer d'éventuels conflits dans la réalisation de vos missions ?",
                  "value": "instances_specifiques_pour_traiter_et_arbitrer_deventuels_conflits_dans_la_realisation_de_vos_missions"
                },
                {
                  "text": "Y a-t-il des échelles d'évaluation des enjeux de vos missions ?",
                  "value": "echelles_evaluation_des_enjeux_de_vos_missions"
                },
                {
                  "text": "Qui, au sein du service/département/direction juge les résultats obtenus ?",
                  "value": "qui_au_sein_juge_les_resultats_obtenus"
                },
                {
                  "text": "Comment sont mesurés les résultats obtenus dans l'accomplissement de vos missions ?",
                  "value": "comment_sont_mesures_les_resultats_obtenus_dans_laccomplissement_de_vos_missions"
                },
                {
                  "text": "Avez-vous déterminé des indicateurs de base pour la gestion de vos différentes missions ?",
                  "value": "avez_vous_determine_des_indicateurs_de_base_pour_la_gestion_de_vos_differentes_missions"
                },
                {
                  "text": "Ces indicateurs sont-ils toujours consolidables ?",
                  "value": "ces_indicateurs_sont_ils_toujours_consolidables"
                },
                {
                  "text": "Quelles sont les raisons qui peuvent expliquer que des indicateurs ne sont pas toujours consolidables ?",
                  "value": "raison_qui_explique_que_des_indicateurs_ne_sont_pas_toujours_consolidables"
                },
                {
                  "text": "Quels sont les pistes d'amélioration de ces indicateurs pour qu'ils puissent le mieux correspondre à une mesure cohérente des objectifs formulés ?",
                  "value": "pistes_damelioaration_de_ses_indicateurs"
                },
                {
                  "text": "Quels sont les processus métiers ?",
                  "value": "processus_metiers"
                },
                {
                  "text": "La gestion des processus couvre-t-elle tous vos processus ?",
                  "value": "la_gestion_des_processus_couvre_t_elle_tous_vos_processus"
                },
                {
                  "text": "Quel est le périmètre couvert ?",
                  "value": "quel_est_le_perimetre_couvert"
                },
                {
                  "text": "Quelles sont les actions menées pour suivre le bon fonctionnement de ces processus ?",
                  "value": "actions_menees_pour_suivre_le_bon_fonctionnement_de_ses_processuss"
                },
                {
                  "text": "Quelles sont les actions menées pour assurer le bon traitement des différents incidents d'incohérence ?",
                  "value": "actions_menees_pour_assurer_le_bon_traitement_des_incidents_d'incoherence"
                },
                {
                  "text": "Quelles sont les actions menées pour assurer l'interfaçage des processus métiers ?",
                  "value": "actions_menees_pour_assurer_linterfacage_des_processuss_metiers"
                },
                {
                  "text": "Quelles sont les actions menées pour assurer la communication ?",
                  "value": "actions_menees_pour_assurer_la_communication"
                },
                {
                  "text": "Quelles sont les actions menées pour garantir la consolidation des données et des résultats ?",
                  "value": "actions_menees_pour_garantir_la_consolidation_des_donnee_et_des_resultats"
                },
                {
                  "text": "Quelles sont les attitudes, compétences et comportement souhaitées pour une gestion performante de vos processus ?",
                  "value": "attitudes_competences_et_comportement_souhaitees_pour_une_gestion_performante_de_vos_processus"
                },
                {
                  "text": "Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?",
                  "value": "comment_acquerir_attitudes_competences_et_comportement_necessaire_a_une_gestion_performante_de_vos_processus"
                },
                {
                  "text": "Quelles types de formation permettraient d'acquérir les attitudes, compétences et comportement nécessaire à une bonne gestion de vos processus métiers ?",
                  "value": "quels_types_de_formation_permettraient_dacquerir_les_attitudes_competences_et_comportement_necessaire_a_une_bonne_gestion_de_vos_processus"
                },
                {
                  "text": "Quels sont les difficultés/préoccupations/problèmes que vous rencontrez dans la réalisation de vos missions ?",
                  "value": "difficultes_preoccupations_problemes_que_vous_rencontrez_dans_la_realisation_de_vos_missions"
                },
                {
                  "text": "Comment procédez-vous pour résoudre les difficultés que vous rencontrez ?",
                  "value": "comment_procedez_vous_pour_resoudre_les_difficultes_que_vous_rencontrez"
                },
                {
                  "text": "Quelles sont les améliorations à apporter pour simplifier votre travail ?",
                  "value": "ameliorations_a_apporter_pour_simplifier_votre_travail"
                },
                {
                  "text": "Quelle est la nature des actions conduites pour l'amélioration de vos missions ?",
                  "value": "nature_des_actions_conduites_pour_lamelioration_de_vos_missions"
                },
                {
                  "text": "Y-a-t-il un mécanisme permettant de s'assurer que les actions conduitent correspondent bien à celles qui sont prévues ?",
                  "value": "mecanisme_permettant_de_sassurer_que_les_actions_conduitent_correspondent_bien_a_celles_qui_sont_prevues"
                },
                {
                  "text": "Comment pensez-vous qu'il faudrait procéder pour améliorer le fonctionnement des processus métiers qui sont à votre charge ?",
                  "value": "comment_pensez_vous_quil_faudrait_proceder_pour_ameliorer_le_fonctionnement_des_processus_metiers_qui_sont_a_votre_charge"
                },
                {
                  "text": "Quelles sont les règles de gestion qu'on peut supprimer sans affecter le bon déroulement de ces processus ?",
                  "value": "regles_de_gestion_quon_peut_supprimer_sans_affecter_le_bon_deroulement_de_ces_processus"
                },
                {
                  "text": "Pensez-vous qu'on pourrait réaliser vos missions en utilisant moins de ressources ?",
                  "value": "pensez_vous_quon_pourrait_realiser_vos_missions_en_utilisant_moins_de_ressources"
                },
                {
                  "text": "Comment devrait-on procéder pour utiliser moins de ressources afin de réaliser vos missions ?",
                  "value": "comment_devrait_on_proceder_pour_utiliser_moins_de_ressources_afin_de_realiser_vos_missions"
                },
                {
                  "text": "Y-a-t-il des erreurs qui surviennent dans la réalisation de vos missions ?",
                  "value": "erreurs_qui_surviennent_dans_la_realisation_de_vos_missions"
                },
                {
                  "text": "Qu'elles sont les raisons qui selon-vous engendre les erreurs ?",
                  "value": "raisons_qui_selon_vous_engendre_les_erreurs"
                },
                {
                  "text": "Comment les éviter ?",
                  "value": "comment_les_eviter"
                },
                {
                  "text": "Recevez-vous des plaintes des acteurs impliqués dans la résolution de vos missions ?",
                  "value": "recevez_vous_des_plaintes_des_acteurs_impliques_dans_la_resolution_de_vos_missions"
                },
                {
                  "text": "Qu'elles sont les raisons qui expliquent ces plaintes ?",
                  "value": "raisons_qui_expliquent_ces_plaintes"
                },
                {
                  "text": "Existe-t-il un système de prise en compte des erreurs et des plaintes des acteurs (agents, clients, ...) de manière à améliorer le fonctionnement des processus ?",
                  "value": "systeme_de_prise_en_compte_des_erreurs_et_des_plaintes_des_acteurs_de_maniere_a_ameliorer_le_fonctionnement_des_processus"
                },
                {
                  "text": "Avez-vous des périodes d'inactivités dans votre service ?",
                  "value": "avez_vous_des_periodes_dinactivites_dans_votre_service"
                },
                {
                  "text": "Quelles sont les raisons qui peuvent expliquer que vous avez des périodes d'inactivités dans votre service ?",
                  "value": "raisons_qui_peuvent_expliquer_que_vous_avez_des_periodes_dinactivites_dans_votre_service"
                },
                {
                  "text": "Comment pensez-vous qu'il faudrait procéder pour régler ce problème ?",
                  "value": "comment_pensez_vous_quil_faudrait_proceder_pour_regler_ce_probleme"
                },
                {
                  "text": "Existe t-il un système permettant de conduire les changements des processus métiers et facilitant l'intégration des améliorations de ceux-ci ?",
                  "value": "existe_til_un_systeme_permettant_de_conduire_les_changements_des_processus_metiers_et_facilitant_lintegration_des_ameliorations_de_ceux_ci"
                },
                {
                  "text": "Quelles sont les actions de communication concernant la démarche, les actions conduites, les résultats obtenus ?",
                  "value": "actions_de_communication_concernant_la_demarche_les_actions_conduites_les_resultats_obtenus"
                },
                {
                  "text": "Existe-t-il un système d'écoute clients de manière à appréhender leurs besoins et mesurer en retour les impacts des conduites de changement des processus et de leur intégration ?",
                  "value": "systeme_decoute_clients_de_maniere_a_apprehender_leurs_besoins_et_mesurer_en_retour_les_impacts_des_conduites_de_changement_des_processus_et_de_leur_integration"
                },
                {
                  "text": "Quelle est selon-vous l'importance de votre travail pour votre organisation ?",
                  "value": "importance_de_votre_travail_pour_votre_organisation"
                },
                {
                  "text": "Obtenez-vous satisfaction de votre travail ?",
                  "value": "obtenez_vous_satisfaction_de_votre_travail"
                },
                {
                  "text": "Comment qualifierez-vous les relations que vous entretenez avec votre supérieur hiérarchique ?",
                  "value": "relations_que_vous_entretenez_avec_votre_superieur_hierarchique"
                },
                {
                  "text": "Connaissez-vous les critères de nomination de l'équipe de gouvernance des processus ?",
                  "value": "criteres_de_nomination_de_lequipe_de_gouvernance_des_processus"
                },
                {
                  "text": "Quelles sont les difficultés que vous avez rencontrées pour prendre en charge votre poste et dans l'exercice de celui-ci ?",
                  "value": "difficultes_rencontrees_pour_prendre_en_charge_votre_poste_et_dans_lexercice_de_celui_ci"
                },
                {
                  "text": "Quels sont les conseils que vous souhaitez donner pour aider d'autres services ou départements ?",
                  "value": "conseils_que_vous_souhaitez_donner_pour_aider_dautres_services_ou_departements"
                },
                {
                  "text": "Quelles satisfactions, à titre personnel, trouvez-vous dans l'exercice de votre fonction ?",
                  "value": "quelles_satisfactions_a_titre_personnel_trouvez_vous_dans_lexercice_de_votre_fonction"
                }
            ]        
        }
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        this._getAllSubmission((response) => {
            // console.log(response);
            this.setState({
                submissionList: response.data,
            });
        });
    }
    render() {
        return (
            <>
                {
                    super.render()
                }
                <div className='dashboard_body'>
                    <div className='dashboard_body_title'>
                        <span className='dashboard_body_title_ text-center'>
                            Liste des soumissions
                        </span>
                    </div>
                    <table>
                        <thead>
                            <tr className="table-head">
                                <th>
                                    <select value={this.state.column1_attr}
                                        onChange={(e) => {
                                            this.setState({
                                                column1_attr: e.target.value
                                            });
                                        }}
                                    >
                                        {
                                            this.state.optionsList.map((option, index)=>(
                                                <option key={"option" + index} value={option.value}>
                                                    {option.text}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </th>
                                <th>
                                    <select value={this.state.column2_attr}
                                        onChange={(e) => {
                                            this.setState({
                                                column2_attr: e.target.value
                                            });
                                        }}
                                    >
                                        {
                                            this.state.optionsList.map((option, index)=>(
                                                <option key={"option" + index} value={option.value}>
                                                    {option.text}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </th>
                                <th>
                                    <select value={this.state.column3_attr}
                                        onChange={(e) => {
                                            this.setState({
                                                column3_attr: e.target.value
                                            });
                                        }}
                                    >
                                        {
                                            this.state.optionsList.map((option, index)=>(
                                                <option key={"option" + index} value={option.value}>
                                                    {option.text}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </th>
                                <th>
                                    <select value={this.state.column4_attr}
                                        onChange={(e) => {
                                            this.setState({
                                                column4_attr: e.target.value
                                            });
                                        }}
                                    >
                                        {
                                            this.state.optionsList.map((option, index)=>(
                                                <option key={"option" + index} value={option.value}>
                                                    {option.text}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </th>
                                <th>
                                    <select value={this.state.column5_attr}
                                        onChange={(e) => {
                                            this.setState({
                                                column5_attr: e.target.value
                                            });
                                        }}
                                    >
                                        {
                                            this.state.optionsList.map((option, index)=>(
                                                <option key={"option" + index} value={option.value}>
                                                    {option.text}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.submissionList.map((submission, index)=>(
                                    <tr key={"submission-" + index}
                                        style={{
                                            background: index%2 !== 0 ? this.VAR.color_1 : "transparent",
                                            color:  index%2 !== 0 ? "#ffffff" : "#000000",
                                            zIndex: "10000"
                                        }}
                                    >
                                        <td className='accountUsername'>
                                            {submission.data[this.state.column1_attr]}
                                        </td>
                                        <td className='accountUsername'>
                                            {submission.data[this.state.column2_attr]}
                                        </td>
                                        <td className='accountUsername'>
                                            {submission.data[this.state.column3_attr]}
                                        </td>
                                        <td className='accountUsername'>
                                            {submission.data[this.state.column4_attr]}
                                        </td>
                                        <td className='accountUsername'>
                                            {submission.data[this.state.column5_attr]}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}


DashboardSubmissionList = connect(mapStateToProps, null)(DashboardSubmissionList);
export default DashboardSubmissionList;