import React from 'react';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */




/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */



/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



/**
 * Importation des variables globals
 */
import VARIABLES from '../model-7-1/utils/_variables.scss';
import GLOBAL from '../config';
/**
 * Importation des helpers
 */
import AccessStore from '../store/accessStore';

import tools from '../helpers/tools.helper';

import SubmissionRequest from '../helpers/submission.request.helper';


class SuperScreen extends React.Component {
    constructor(props) {
        super(props);
        this.VAR = VARIABLES;
        this.GLOBAL = new GLOBAL();

        this.state = {
            isLoading: false,
            pageIsReady: false,
        }
        
        this.logo = this.GLOBAL.images.logo_;
        
        this.tools = new tools();
        this.accessStore = new AccessStore( this.props.dispatch);
        this.SubmissionRequest = new SubmissionRequest();
    }

    componentDidMount() {
        
    }

    _generateRandomColor(_number_){
        let maxVal = 0xFFFFFF; // 16777215
        let randomNumber = _number_ * maxVal; 
        randomNumber = Math.floor(randomNumber);
        randomNumber = randomNumber.toString(16);
        let randColor = randomNumber.padStart(6, 0);   
        return `#${randColor.toUpperCase()}`
    }

    _generateID(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++)
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    /** 
     * 
     * L'ensemble des requests pour les soumissions
    */
    _getAllSubmission(success_callback=()=>{},  failed_callback=()=>{}) {
        this.SubmissionRequest.getAll().then((response) => {
            success_callback( response );
        }).catch((e) => { 
            this._manageError(e, () => {
                failed_callback( e );
            });
        });
    }

    _addSubmission(data, success_callback=()=>{},  failed_callback=()=>{}) {
        this.SubmissionRequest.add(data).then((response) => {
            success_callback( response );
        }).catch((e) => { 
            this._manageError(e, () => {
                failed_callback( e );
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if (error.toString() === "Error: Network Error") {
            alert("Aucun accès à internet :(");
            callback();
        }
    }

    render() {
        return (
            <>

            </>
        )
    }
}

export default SuperScreen;